import React, { useEffect, useRef, useState } from 'react';
import Logo3DCanvas from '../components/logo3d';
import { AnimatePresence, motion } from 'framer-motion';
import { Swiper, SwiperSlide } from 'swiper/react';
import WOW from 'wowjs';
import 'animate.css';
import 'swiper/swiper-bundle.css';


const Home = () => {

    const [visibleIndex, setVisibleIndex] = useState(0);
    const [acfData, setAcfData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [clientLogos, setClientLogos] = useState([]);
    const [headerVideoUrl, setHeaderVideoUrl] = useState(null); // Estado para el video del header
    const [isHeaderPopupOpen, setIsHeaderPopupOpen] = useState(false); // Estado para el popup del header

    const [servicioVideoUrl, setServicioVideoUrl] = useState(null); // Estado para los videos de los servicios
    const [isServicioPopupOpen, setIsServicioPopupOpen] = useState(false); // Estado para el popup de los servicios

    const swiperRef = useRef(null);

    useEffect(() => {
        // Configura el autoplay manualmente con setInterval
        const autoplay = setInterval(() => {
            if (swiperRef.current) {
                swiperRef.current.slideNext(); // Mueve a la siguiente diapositiva
            }
        }, 2000); // 3 segundos entre cada transición

        return () => clearInterval(autoplay); // Limpiar el interval cuando el componente se desmonte
    }, []);

    // Abrir el popup del header y actualizar la URL del video
    const handleOpenHeaderPopup = (e, videoSourceUrl) => {
        e.preventDefault();
        
        // Cierra el popup de servicio si está abierto
        if (isServicioPopupOpen) {
            handleCloseServicioPopup();
        }

        setHeaderVideoUrl(videoSourceUrl); // Actualizamos la URL del video del header
        setIsHeaderPopupOpen(true); // Abrimos el popup del header
    };

    // Cerrar el popup del header
    const handleCloseHeaderPopup = () => {
        setIsHeaderPopupOpen(false);
        setHeaderVideoUrl(null); // Reiniciamos la URL del video del header
    };

    // Abrir el popup de servicio y actualizar la URL del video
    const handleOpenServicioPopup = (e, videoSourceUrl) => {
        e.preventDefault();

        // Cierra el popup del header si está abierto
        if (isHeaderPopupOpen) {
            handleCloseHeaderPopup();
        }

        setServicioVideoUrl(videoSourceUrl); // Actualizamos la URL del video de servicio
        setIsServicioPopupOpen(true); // Abrimos el popup del servicio
    };

    // Cerrar el popup de servicio
    const handleCloseServicioPopup = () => {
        setIsServicioPopupOpen(false);
        setServicioVideoUrl(null); // Reiniciamos la URL del video del servicio
    };



    const [projects, setProjects] = useState([]);
    const [serviciosData, setServiciosData] = useState([]);
    const [numbersImages, setNumbersImages] = useState({
        tourFrente: '',
        tourAtras: '',
        threeDFrente: '',
        threeDAtras: '',
        essenceFrente: '',
        essenceAtras: '',
      });

    useEffect(() => {

        const fetchServicios = async () => {
            try {
                const response = await fetch('https://interprika.com/cms/wp-json/wp/v2/servicio?_embed');
                const data = await response.json();
    
                const reversedDataFilreados = data.reverse();
    
                // Filtra solo aquellos servicios que tengan la sección habilitada (mostrar_seccion: true)
                const serviciosFiltrados = reversedDataFilreados.filter(servicio => servicio.acf?.nuestros_servicios?.mostrar_seccion);
    
                // Itera sobre los servicios filtrados para obtener la imagen destacada y los videos
                const serviciosConMedia = await Promise.all(serviciosFiltrados.map(async (servicio) => {
                    const featuredImage = servicio._embedded?.['wp:featuredmedia']?.[0]?.source_url || './images/default.png';
                    
                    // Si el servicio tiene un video_pop, busca la URL del video
                    let videoUrl = null;
                    if (servicio.acf?.nuestros_servicios?.video_pop) {
                        const videoId = servicio.acf.nuestros_servicios.video_pop;
                        try {
                            const videoResponse = await fetch(`https://interprika.com/cms/wp-json/wp/v2/media/${videoId}`);
                            const videoData = await videoResponse.json();
                            videoUrl = videoData.source_url; // Guardamos la URL del video
                        } catch (error) {
                            console.error(`Error fetching video for servicio ID: ${servicio.id}`, error);
                        }
                    }
    
                    return {
                        ...servicio,
                        featuredImage: featuredImage, // Imagen destacada del servicio
                        videoUrl: videoUrl // URL del video si existe
                    };
                }));
    
                setServiciosData(serviciosConMedia); // Guarda los servicios con la imagen y video
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        };
    
        fetchServicios();
    }, []);

    useEffect(() => {
        if (acfData && acfData.numbers_resultados) {
            const fetchProjects = async () => {
                try {       
                    const response = await fetch('https://interprika.com/cms/wp-json/wp/v2/proyecto?_embed');
                    const data = await response.json();

                    const tourFrenteResponse = await fetch(`https://interprika.com/cms/wp-json/wp/v2/media/${acfData.numbers_resultados.tour.frente}`);
                    const tourFrenteData = await tourFrenteResponse.json();
            
                    const tourAtrasResponse = await fetch(`https://interprika.com/cms/wp-json/wp/v2/media/${acfData.numbers_resultados.tour.atras}`);
                    const tourAtrasData = await tourAtrasResponse.json();
            
                    const threeDFrenteResponse = await fetch(`https://interprika.com/cms/wp-json/wp/v2/media/${acfData.numbers_resultados["3d"].frente}`);
                    const threeDFrenteData = await threeDFrenteResponse.json();
            
                    const threeDAtrasResponse = await fetch(`https://interprika.com/cms/wp-json/wp/v2/media/${acfData.numbers_resultados["3d"].atras}`);
                    const threeDAtrasData = await threeDAtrasResponse.json();
            
                    const essenceFrenteResponse = await fetch(`https://interprika.com/cms/wp-json/wp/v2/media/${acfData.numbers_resultados.essence.frente}`);
                    const essenceFrenteData = await essenceFrenteResponse.json();
            
                    const essenceAtrasResponse = await fetch(`https://interprika.com/cms/wp-json/wp/v2/media/${acfData.numbers_resultados.essence.atras}`);
                    const essenceAtrasData = await essenceAtrasResponse.json();   
                    
                    const reversedData = data.reverse();

                    const featuredProjects = reversedData.filter(project => project.acf.proyecto_destacado);
                    
                    const projectsData = await Promise.all(
                    featuredProjects.map(async (project) => {
                        const iconResponse = await fetch(`https://interprika.com/cms/wp-json/wp/v2/media/${project.acf.icono}`);
                        const iconData = await iconResponse.json();
                        
                        return {
                        id: project.id,
                        title: project.acf.titulo,
                        description: project.acf.descripcion,
                        button: project.acf.boton,
                        imageUrl: project._embedded['wp:featuredmedia']?.[0]?.source_url,
                        acfIconUrl: iconData.source_url,
                        };
                    })
                    );

                    setNumbersImages({
                    tourFrente: tourFrenteData.source_url,
                    tourAtras: tourAtrasData.source_url,
                    threeDFrente: threeDFrenteData.source_url,
                    threeDAtras: threeDAtrasData.source_url,
                    essenceFrente: essenceFrenteData.source_url,
                    essenceAtras: essenceAtrasData.source_url,
                    });

                    setProjects(projectsData);
                } catch (error) {
                    console.error('Error fetching projects:', error);
                }
            };

            fetchProjects();
        }
    }, [acfData]);
    
  
    useEffect(() => {
        // Fetch ACF data from WordPress API
        const fetchData = async () => {
            try {
                const response = await fetch('https://interprika.com/cms/wp-json/wp/v2/pages/14');
                const data = await response.json();
                setAcfData(data.acf);
    
                // Si hay un ID de video, hacemos la solicitud para obtener la URL
                if (data.acf?.header_home?.video) {
                    const videoId = data.acf.header_home.video;
                    const videoResponse = await fetch(`https://interprika.com/cms/wp-json/wp/v2/media/${videoId}`);
                    const videoData = await videoResponse.json();
                    setHeaderVideoUrl(videoData.source_url);  // Guardamos la URL del video en el estado
                    // También la añadimos a acfData
                    setAcfData((prev) => ({
                        ...prev,
                        header_home: {
                            ...prev.header_home,
                            videoUrl: videoData.source_url
                        }
                    }));
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        // Fetch client logos from WordPress API with embedded media
        const fetchClientLogos = async () => {
            try {
            const response = await fetch('https://interprika.com/cms/wp-json/wp/v2/cliente?_embed');
            const data = await response.json();
            const logos = data.map((post) => {
                const media = post._embedded['wp:featuredmedia']?.[0]?.source_url;
                return media ? media : null;
            }).filter(Boolean);
            setClientLogos(logos);
            } catch (error) {
            console.error('Error fetching client logos:', error);
            }
        };
    
        fetchData();
        fetchClientLogos();
    }, []);
    
    useEffect(() => {
        // Cambiar texto cada 3 segundos
        const interval = setInterval(() => {
            setVisibleIndex((prevIndex) => (prevIndex + 1) % acfData?.header_home?.textos_animados.length);
        }, 3000);
    
        return () => clearInterval(interval); // Limpiar el intervalo al desmontar
    }, [acfData]);

    useEffect(() => {
        const handleLoad = () => {
            setTimeout(() => {
                new WOW.WOW({
                    live: true, // Mantener activo el monitoreo en vivo
                }).init();
            }, 2000); // Pausa de 2 segundos antes de iniciar WOW.js
        };
    
        // Escuchar el evento de carga completa
        window.addEventListener('load', handleLoad);
    
        // Limpiar el listener cuando el componente se desmonte
        return () => {
            window.removeEventListener('load', handleLoad);
        };
    }, []);    

    if (loading) {
        return (
            <div className='w-full h-full bg-black fixed top-0 z-[999999999] flex flex-col justify-center items-center'>
                <img className='w-full h-[44px] object-contain' src='./images/icons/logo.svg' alt="" />
            </div>
        );
    }
    return (
        <div className='bg-white'>
            <div className='relative w-full'>
                <img className='w-full max-md:h-[794px]' src='./images/fondo-header-home.png' alt="" />
                <div className='overflow-hidden absolute w-full top-0 md:h-full'>
                    <div className='max-lg:flex-col h-full flex justify-between items-center w-[1280px] 2xl:w-[1440px] max-w-full px-[30px] lg:px-[100px] mx-auto pb-10'>
                    <div className='w-full lg:w-6/12 flex flex-col gap-3 mt-[50px]' data-wow-delay="2s" data-wow-duration="2s">
                        <h2 className='text-white text-[38px] leading-[38px] lg:text-[80px] lg:leading-[80px] font-bold uppercase'>
                        {acfData?.header_home?.titulo}
                        </h2>
                        <div className='relative h-[74px] lg:h-[138px] overflow-hidden'>
                            <AnimatePresence mode="wait">
                                <motion.div
                                    key={visibleIndex}  // Esto ayuda a framer-motion a identificar el elemento que está cambiando
                                    className='absolute top-0 w-full text-[30px] leading-[36px] lg:text-[64px] lg:leading-[64px] font-bold'
                                    style={{ color: acfData?.header_home?.textos_animados[visibleIndex].color }}
                                    initial={{ y: '-100%', opacity: 0 }}
                                    animate={{ y: '0%', opacity: 1 }}
                                    exit={{ y: '100%', opacity: 0 }}
                                    transition={{
                                        duration: 1,
                                        ease: 'easeInOut',
                                    }}
                                >
                                    {acfData?.header_home?.textos_animados[visibleIndex].texto}
                                </motion.div>
                            </AnimatePresence>
                        </div>
                        {/* Botón para abrir el popup */}
                        <a className='text-white flex gap-2 items-center py-3' href="" 
                            onClick={(e) => {
                                e.preventDefault(); // Previene el comportamiento por defecto del enlace
                                handleOpenHeaderPopup(e, acfData?.header_home?.videoUrl); // Abre el popup y pasa la URL del video
                            }}
                        >
                            <img className='h-[20px]' src="./images/icons/play.svg" alt="" /> {acfData?.header_home.boton}
                        </a>
                    </div>

                    <div className='w-full lg:w-6/12 h-[500px] py-7 mb-[50px] xl:h-[450px] 2xl:h-[650px]' data-wow-duration="2s">
                        <Logo3DCanvas />
                    </div>
                    </div>

                    {/* Fondo diagonal */}
                    <div className='mt-[-100px]'>
                    <div className='clip-diagonal-orange'></div>
                    <div className='clip-diagonal-white'></div>
                    </div>
                </div>

                {/* Popup del video */}
                <AnimatePresence>
                    {isHeaderPopupOpen && (
                    <motion.div
                        className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-75 z-[9999] flex items-center justify-center"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        onClick={handleCloseHeaderPopup} // Cerrar al hacer clic fuera del contenido
                    >
                        <motion.div
                            className="bg-white p-6 relative w-[90%] lg:w-[60%] h-auto"
                            initial={{ scale: 0.5 }}
                            animate={{ scale: 1 }}
                            exit={{ scale: 0.5 }}
                            onClick={(e) => e.stopPropagation()} // Evita cerrar el popup al hacer clic en el video
                        >
                        {/* Video del ACF del header */}
                        <video width="100%" height="400px" controls>
                            <source src={headerVideoUrl} type="video/mp4" />
                            Tu navegador no soporta la etiqueta de video.
                        </video>

                        {/* Botón de cierre dentro del popup */}
                        <button
                            className="absolute top-0 right-2 text-[#f7921e] font-bold text-[20px]"
                            onClick={handleCloseHeaderPopup}
                        >
                            x
                        </button>
                        </motion.div>
                    </motion.div>
                    )}
                </AnimatePresence>
                <AnimatePresence>
                    {isServicioPopupOpen && (
                    <motion.div
                        className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-75 z-[9999] flex items-center justify-center"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        onClick={handleCloseServicioPopup} // Cerrar al hacer clic fuera del contenido
                    >
                        <motion.div
                            className="bg-white p-6 relative w-[90%] lg:w-[60%] h-auto"
                            initial={{ scale: 0.5 }}
                            animate={{ scale: 1 }}
                            exit={{ scale: 0.5 }}
                            onClick={(e) => e.stopPropagation()} // Evita cerrar el popup al hacer clic en el video
                        >
                        {/* Video del servicio */}
                        <video width="100%" height="400px" controls>
                            <source src={servicioVideoUrl} type="video/mp4" />
                            Tu navegador no soporta la etiqueta de video.
                        </video>

                        {/* Botón de cierre dentro del popup */}
                        <button
                            className="absolute top-0 right-2 text-[#f7921e] font-bold text-[20px]"
                            onClick={handleCloseHeaderPopup}
                        >
                            x
                        </button>
                        </motion.div>
                    </motion.div>
                    )}
                </AnimatePresence>
                </div>


            {acfData?.bienvenida && acfData?.bienvenida.mostrar_seccion && (
                <div className="text-center my-14 wow animate__animated animate__fadeInUp" data-wow-duration="2s">
                    <h1 className="text-4xl font-bold">{acfData?.bienvenida.titulo}</h1>
                    <div dangerouslySetInnerHTML={{ __html: acfData?.bienvenida.descripcion }}></div>
                </div>
            )}

            {acfData?.nuestros_servicios && acfData?.nuestros_servicios.mostrar_seccion && (
                <div>
                    <div className="relative w-full bg-gray-800 text-center text-white pt-6 pb-2">
                        {acfData?.nuestros_servicios?.titulo && (
                            <div>
                                <div className="absolute inset-0 bg-gray-800 transform skew-y-2 origin-top-left"></div>
                                <h2 className="relative z-10 text-2xl lg:text-4xl uppercase wow animate__animated animate__fadeInLeft" data-wow-duration="2s">
                                    <span dangerouslySetInnerHTML={{ __html: acfData?.nuestros_servicios?.titulo }}></span>
                                </h2>
                            </div>
                        )}
                    </div>

                    <div className='py-[40px] lg:py-[80px]'>
                        <div className='w-[1280px] 2xl:w-[1440px] max-w-full px-[30px] lg:px-[100px] mx-auto'>

                            {serviciosData.map((servicio, index) => (
                                <section key={index} className={`flex flex-col ${index % 2 === 0 ? 'md:flex-row' : 'md:flex-row-reverse'} items-center my-10 lg:my-20`}>
                                
                                {/* Imagen de fondo */}
                                <div  onClick={(e) => {
                                        e.preventDefault(); // Previene el comportamiento por defecto del enlace
                                        handleOpenServicioPopup(e, servicio.videoUrl); // Abre el popup y pasa la URL del video
                                    }} className={`flex-1 flex cursor-pointer justify-center relative ${index % 2 === 0 ? 'before:absolute before:bottom-[-10px] before:left-[-15px]' : 'before:absolute before:bottom-[-10px] before:right-[-15px]'} before:w-[30px] before:rounded-tr-[2px] before:bg-white before:h-1/2 after:content-[''] after:h-[30px] after:w-[30px] after:rotate-45 after:bg-white ${index % 2 === 0 ? 'after:left-[-22px] after:top-[48%]' : 'after:right-[-22px] after:top-[48%]'} after:absolute wow animate__animated ${index % 2 === 0 ? 'animate__fadeInLeft' : 'animate__fadeInRight'}`} data-wow-duration="2s">
                                    <img src={servicio?.featuredImage} alt={servicio?.acf?.nuestros_servicios?.titulo || 'Servicio'} className="w-full h-auto rounded-[15px]" />
                                </div>

                                {/* Información del servicio */}
                                <div className={`max-w-full flex-1 flex flex-col justify-center  md:mt-0 mt-6 ${index % 2 === 0 ? 'md:ml-8' : 'md:mr-8'} wow animate__animated`} data-wow-duration="2s">
                                    <h2 className="text-lg md:text-[32px] leading-[24px] md:leading-[32px] font-medium uppercase">
                                        <span dangerouslySetInnerHTML={{ __html: servicio?.acf?.nuestros_servicios?.titulo }}></span>
                                    </h2>

                                    {/* Etiquetas o tags */}
                                    <div className="flex flex-wrap md:flex-row gap-3 my-3">
                                        {servicio?.acf?.nuestros_servicios?.tags?.map((tag, tagIndex) => (
                                            <span key={tagIndex} className="bg-[#D7D7D7] px-2 py-1">{tag.tag}</span>
                                        ))}
                                    </div>

                                    {/* Descripción del servicio */}
                                    <p className="mb-4">
                                        {servicio?.acf?.nuestros_servicios?.descripcion}
                                    </p>

                                    {/* Botones */}
                                    <div className="flex space-x-4">
                                        {servicio?.acf?.nuestros_servicios?.botones?.map((boton, botonIndex) => (
                                            
                                            botonIndex % 2 === 0 ? 
                                            (
                                                <div>
                                                    <a key={botonIndex} href={boton.link} className={`bg-black text-white font-medium px-4 py-2`}>{boton.texto}</a>
                                                </div>
                                            )
                                            :
                                            (
                                                <div>
                                                    <a key={botonIndex} href={boton.link} className={`bg-[#F89D38] text-black font-medium px-4 py-2`}>{boton.texto}</a>
                                                </div>
                                            )
                                        ))}
                                    </div>
                                </div>

                                </section>
                            ))}
                        </div>
                    </div>  
                </div>
            )}

            {acfData?.clientes && acfData?.clientes.mostrar_seccion && (
                <div className='w-[1280px] 2xl:w-[1440px] max-w-full px-[30px] lg:px-[100px] mx-auto pb-[40px] lg:pb-[80px] overflow-hidden'>
                    <div className='w-full'>
                        <Swiper
                            onSwiper={(swiper) => {
                                swiperRef.current = swiper; // Guarda la instancia de Swiper
                            }}
                            spaceBetween={0}
                            slidesPerView={2}
                            loop={true}
                            speed={1000} // Velocidad de transición
                            breakpoints={{
                                640: { slidesPerView: 2 },
                                768: { slidesPerView: 3 },
                                1024: { slidesPerView: 5 },
                            }}
                        >
                            {clientLogos.map((logo, index) => (
                                <SwiperSlide key={index}>
                                    <div className='px-2'>
                                        <img
                                            src={logo}
                                            alt={`Client logo ${index}`}
                                            className='w-32 h-32 object-contain mx-auto'
                                        />
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>
            )}            
            
            {acfData?.seccion_proyectos_destacados && acfData?.seccion_proyectos_destacados.mostrar_seccion && (
                <div className='relative'>
                    <div className='w-[1280px] 2xl:w-[1440px] max-w-full px-[30px] lg:px-[100px] mx-auto'>
                        <h2 className="relative z-10 text-2xl lg:text-4xl uppercase pb-10 wow animate__animated animate__fadeInLeft" data-wow-duration="2s">
                            <span dangerouslySetInnerHTML={{ __html: acfData?.numbers_resultados?.titulo }}></span>
                        </h2>
                    </div>
                    <div className='overflow-hidden max-md:bg-black max-md:before:hidden relative before:w-[160vw] before:h-[70vw] before:absolute before:top-[20vw] 2xl:before:top-[10vw] before:left-[-30%] before:rounded-tr-[9999px] before:rounded-tl-[9999px] before:z-[9999] before:content-[&quot;&quot;] before:bg-black py-10 md:py-28'>
                        <div className='flex max-md:flex-col justify-center md:justify-between max-md:items-center w-[1280px] 2xl:w-[1440px] max-w-full px-[30px] lg:px-[100px] mx-auto z-[999999] relative'>
                            <div className="flip-card">
                                <div className="flip-card-inner">
                                    <img src={numbersImages.tourFrente} alt='' className="flip-card-front" />
                                    <img src={numbersImages.tourAtras} alt='' className="flip-card-back" />
                                </div>
                            </div>

                            <div className="flip-card max-md:my-5 md:mt-[-100px]">
                                <div className="flip-card-inner">
                                    <img src={numbersImages.threeDFrente} alt='' className="flip-card-front" />
                                    <img src={numbersImages.threeDAtras} alt='' className="flip-card-back" />
                                </div>
                            </div>

                            <div className="flip-card">
                                <div className="flip-card-inner">
                                    <img src={numbersImages.essenceFrente} alt='' className="flip-card-front" />
                                    <img src={numbersImages.essenceAtras} alt='' className="flip-card-back" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='mt-[-100px] relative z-[99999]'>
                        <div className='clip-diagonal-white'></div>
                    </div>
                </div>
            )}

            {acfData?.seccion_proyectos_destacados && acfData?.seccion_proyectos_destacados.mostrar_seccion && (
                <div className='relative py-16 bg-white overflow-hidden'>
                    <div className='max-w-full mx-auto'>
                        <h2 className='text-4xl lg:text-5xl text-center uppercase mb-8 wow animate__ animate__fadeInDown' dangerouslySetInnerHTML={{ __html: acfData.seccion_proyectos_destacados.titulo }}></h2>
                        <div className='text-center text-lg mb-12 wow animate__ animate__fadeInUp' dangerouslySetInnerHTML={{ __html: acfData.seccion_proyectos_destacados.descripcion }}></div>
                        {projects.map((project, index) => (
                            index % 2 === 0 ? (
                                <section key={index} className="relative bg-black wow animate__animated animate__fadeInLeft" data-wow-duration="2s">
                                    <div className='clip-diagonal-black-200-t'></div>
                                    <div className='flex flex-col lg:flex-row items-center my-[150px] w-[1280px] 2xl:w-[1440px] max-w-full px-[30px] lg:px-[100px] mx-auto'>
                                        <div className="w-full lg:w-1/2 lg:pr-10 relative">
                                            <img src={project.imageUrl} alt={project.title} className="w-full mb-10 wow animate__animated animate__fadeInDown" data-wow-duration="4s" />
                                            <img src={project.acfIconUrl} alt={project.title} className="w-[80%] -left-1/4 absolute z-[9999] top-0 wow animate__animated animate__fadeInUp" data-wow-duration="4s" />
                                        </div>
                                        <div className="w-full lg:w-1/2 lg:pl-10 mt-6 lg:mt-0 relative z-40">
                                            <h3 className="text-3xl mb-4 text-left text-white uppercase" dangerouslySetInnerHTML={{ __html: project.title }}></h3>
                                            <p className="mb-6 text-white text-sm" dangerouslySetInnerHTML={{ __html: project.description }}></p>
                                            <a href={project.button.link} className="inline-block border border-white text-white px-5 py-2 text-sm uppercase tracking-[3px] shadow-lg">
                                                {project.button.texto}
                                            </a>
                                        </div>
                                    </div>
                                    <div className='clip-diagonal-black-200-b'></div>
                                </section>
                            ) : (
                                <section key={index} className="relative wow animate__animated animate__fadeInRight" data-wow-duration="2s">
                                    <div className='flex flex-col lg:flex-row items-center w-[1280px] 2xl:w-[1440px] max-w-full px-[30px] lg:px-[100px] mx-auto'>
                                        <div className="w-full lg:w-1/2 lg:pr-10 mb-7 md:mb-0 mt-6 lg:mt-0 relative z-40">
                                            <h3 className="text-3xl mb-4 text-right text-black uppercase" dangerouslySetInnerHTML={{ __html: project.title }}></h3>
                                            <p className="mb-6 text-right text-black text-sm" dangerouslySetInnerHTML={{ __html: project.description }}></p>
                                            <div className='text-right'>
                                                <a href={project.button.link} className="inline-block border bg-black text-white px-5 py-2 text-sm uppercase tracking-[3px] shadow-lg">
                                                    {project.button.texto}
                                                </a>
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-1/2 lg:pl-10 relative">
                                            <img src={project.imageUrl} alt={project.title} className="w-full mb-20 wow animate__animated animate__fadeInDown" data-wow-duration="4s" />
                                            <img src={project.acfIconUrl} alt={project.title} className="w-2/3 left-[-18%] absolute z-[9999] bottom-0 wow animate__animated animate__fadeInUp" data-wow-duration="4s" />
                                        </div>
                                    </div>
                                </section>
                            )
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
}

export default Home;