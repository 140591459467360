import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Layout from './components/Layout';
import Home from './pages/Home';
import Contacto from './pages/Contacto';
import Blog from './pages/Blog';
import DetallesBlog from './pages/DetallesBlog';
import TourVirtual from './pages/Servicios/Tour';
import Matterport from './pages/Servicios/Matterport';
import Marketing from './pages/Servicios/Marketing';
import D3tech from './pages/Servicios/3d-tech';

const App = () => {
  return (
    <Layout>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contacto" element={<Contacto />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/:id" element={<DetallesBlog />} />
        <Route path="/servicios/tour-virtual" element={<TourVirtual />} />
        <Route path="/servicios/matterport" element={<Matterport />} />
        <Route path="/servicios/marketing-digital" element={<Marketing />} />
        <Route path="/servicios/tecnologia-3d" element={<D3tech />} />
      </Routes>
    </Layout>
  );
}

export default App;
