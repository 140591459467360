// src/App.jsx
import React, { useEffect, useState } from 'react';
import Carousel from '../../components/Carousel';
import { motion } from 'framer-motion';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';  // Importa el módulo de navegación
import 'swiper/css';
import 'swiper/css/navigation';
import WOW from 'wowjs';
import 'animate.css';
import { getMedia } from '../../utils/get-media.ts';

const Tour = () => {

    const [slides, setSlides] = useState([]);
    const [apartados, setApartados] = useState([]);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({});
    const [tours, setTours] = useState([]);
    const [selectedTour, setSelectedTour] = useState(null); // Para manejar el tour seleccionado
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [etiqueta, setEtiqueta] = useState({});
    const [imageUrls, setImageUrls] = useState([]);
    const [reserva, setReserva] = useState(null);
    const [reservaImageUrl, setReservaImageUrl] = useState(null);

    useEffect(() => {
        const fetchTours = async () => {
            try {
                const response = await fetch('https://interprika.com/cms/wp-json/wp/v2/tour-virtual?_embed');
                const data = await response.json();
    
                // Solo toma los tours que tienen "mostrar_en_la_galeria" habilitado
                const toursFiltrados = data.filter(tour => tour.acf.mostrar_en_la_galeria);
                setTours(toursFiltrados);
            } catch (error) {
                console.error('Error fetching tours:', error);
            }
        };
    
        fetchTours();
    }, []);

    useEffect(() => {
        if (data.expertos && data.expertos.imagenes) {
            const fetchImages = async () => {
                try {
                    const ids = data.expertos.imagenes.map(img => img.imagen);
                    const urls = await Promise.all(ids.map(id => getMedia(id)));
                    setImageUrls(urls);
                } catch (error) {
                    console.error('Error fetching images:', error);
                }
            };
    
            fetchImages();
        }
    }, [data.expertos]);

    useEffect(() => {
        const fetchApartados = async () => {
            try {
                const response = await fetch('https://interprika.com/cms/wp-json/wp/v2/servicio/70?_embed');
                const data = await response.json();
    
                if (data.acf) {
                    setData(data.acf);
    
                    // Guardar la reserva si está disponible
                    if (data.acf.reserva && data.acf.reserva.mostrar_en_el_sitio) {
                        setReserva(data.acf.reserva);
                        
                        // Cargar la imagen de la reserva
                        const imageUrl = await getMedia(data.acf.reserva.imagen);
                        setReservaImageUrl(imageUrl);  // Guarda la URL de la imagen
                    }
                }
    
                // Si hay apartados en el ACF, los guardamos
                if (data.acf?.apartados) {
                    const apartadosData = await Promise.all(data.acf.apartados.map(async (apartado) => {
                        // Para cada apartado, obtenemos la imagen destacada si está disponible
                        const imageId = apartado.imagen;
                        let imageUrl = null;
    
                        if (imageId) {
                            imageUrl = await getMedia(imageId);
                        }
    
                        return {
                            ...apartado,
                            imageUrl: imageUrl || '../images/default.png', // Imagen por defecto si no hay imagen
                        };
                    }));
    
                    setApartados(apartadosData);
                }
    
                // SLIDES
                if (data.acf?.slides) {
                    const getEtiqueta = async () => {
                        const etiqueta = data.acf.slides.etiqueta;
                        const icono = await getMedia(etiqueta.icono);
                        return {
                            icono: icono,
                            texto: etiqueta.texto
                        };
                    }
                    setEtiqueta(await getEtiqueta());
    
                    const slidesData = await Promise.all(data.acf.slides.lista_de_slides.map(async (slide) => {
                        const imageId = slide.fondo;
                        let imageUrl = null;
    
                        if (imageId) {
                            imageUrl = await getMedia(imageId);
                        }
    
                        return {
                            title: slide.titulo,
                            text: slide.texto,
                            imageUrl: imageUrl || '../images/default.png', // Imagen por defecto si no hay imagen
                        };
                    }));
    
                    setSlides(slidesData);
                }
    
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        };
    
        fetchApartados();
    }, []);
    

    useEffect(() => {
        const handleLoad = () => {
            setTimeout(() => {
                new WOW.WOW({
                    live: true, // Mantener activo el monitoreo en vivo
                }).init();
            }, 2000); // Pausa de 2 segundos antes de iniciar WOW.js
        };
    
        // Escuchar el evento de carga completa
        window.addEventListener('load', handleLoad);
    
        // Limpiar el listener cuando el componente se desmonte
        return () => {
            window.removeEventListener('load', handleLoad);
        };
    }, []);  

    const [camaras, setCamaras] = useState([]);
    const [activeSlide, setActiveSlide] = useState(0);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        const fetchCamaras = async () => {
            try {
                const response = await fetch('https://interprika.com/cms/wp-json/wp/v2/camara?_embed');
                const data = await response.json();
    
                // Obtener las cámaras recomendadas
                const camarasFiltradas = data.filter(camara => camara.acf.camara_recomendada);
                setCamaras(camarasFiltradas);
            } catch (error) {
                console.error('Error fetching cameras:', error);
            }
        };
    
        fetchCamaras();
    }, []);

    const toggleCircle = () => {
        setIsOpen(!isOpen);
    };

    const handleOpenPopup = (tour) => {
        setSelectedTour(tour); // Establece el tour seleccionado
        setIsPopupOpen(true); // Abre el popup
    };
    
    const handleClosePopup = () => {
        setIsPopupOpen(false); // Cierra el popup
    };

    if (loading) {
        return (
            <div className='w-full h-full bg-black fixed top-0 z-[999999999] flex flex-col justify-center items-center'>
                <img className='w-full h-[44px] object-contain' src='../images/icons/logo.svg' alt="" />
            </div>
        );
    }

    return (
        <div>
            {/* <pre>{JSON.stringify(apartados, null, 2)}</pre> */}
            <div className='bg-black'>
                <div className='min-h-full'>
                    <Carousel slides={slides} color={'#0D238B'} colorDegradado={'#92C5BE'} />
                </div>
                <div className='relative w-[1280px] 2xl:w-[1440px] max-w-full px-[30px] lg:px-[100px] mx-auto mb-[-100px]'>
                    <div className='absolute bottom-[30px]'>
                        <p className='text-white flex items-center gap-2 p-4 md:p-5 pl-0 max-md:text-[10px]'>
                            <img className='h-[30px] md:h-[40px]' src={etiqueta.icono} alt="" /> <span dangerouslySetInnerHTML={{ __html: etiqueta.texto }} />
                        </p>
                    </div>
                </div>
                <div className='clip-diagonal-turquoise !bg-[#0D238B]'>

                </div>
                <div className='clip-diagonal-white'>

                </div>
            </div>
            <div className='py-[20px] lg:py-[60px]'>
                <div className='w-[1280px] 2xl:w-[1440px] max-w-full px-[30px] lg:px-[100px] mx-auto'>
                    <div className="text-center mb-[160px] mt-[120px]">
                        <h1 className="text-[22px] md:text-[32px] uppercase relative w-[350px] max-w-full mx-auto wow animate__animated animate__fadeInDown" data-wow-duration="2s">
                            <span className='[&_strong]:text-[#FF73B9] ' dangerouslySetInnerHTML={{ __html: data.titulos_apartados.titulo }} />
                        </h1>
                        <p className="text-sm md:text-lg mt-4 pt-6 w-[875px] max-w-full mx-auto wow animate__animated animate__fadeInUp" data-wow-duration="2s" dangerouslySetInnerHTML={{ __html: data.titulos_apartados.descripcion }} />
                    </div>
                    {apartados.map((apartado, index) => (
                        <section 
                            key={index} 
                            className={`flex flex-col ${index % 2 === 0 ? 'md:flex-row' : 'md:flex-row-reverse'} items-center my-10 lg:my-20`}
                        >
                            {/* Imagen */}
                            <div className={`flex-1 flex justify-center relative ${index % 2 === 0 ? 'before:absolute before:bottom-[-10px] before:left-[-15px]' : 'before:absolute before:bottom-[-10px] before:right-[-15px]'} before:w-[30px] before:rounded-tr-[2px] before:bg-white before:h-1/2 after:content-[''] after:h-[30px] after:w-[30px] after:rotate-45 after:bg-white ${index % 2 === 0 ? 'after:left-[-22px] after:top-[48%]' : 'after:right-[-22px] after:top-[48%]'} after:absolute wow animate__animated ${index % 2 === 0 ? 'animate__fadeInLeft' : 'animate__fadeInRight'}`} data-wow-duration="2s">
                                <img src={apartado.imageUrl} alt="Apartado" className="w-full h-auto rounded-[15px]" />
                            </div>

                            {/* Contenido */}
                            <div className={`max-w-full flex-1 flex flex-col justify-center mt-6 md:mt-0 ${index % 2 === 0 ? 'md:ml-8 wow animate__animated animate__fadeInLeft' : 'md:mr-8 wow animate__animated animate__fadeInRight'}`} data-wow-duration="2s">
                                <h2 className="text-[18px] md:text-[32px] leading-[32px] font-medium uppercase [&_strong]:text-[#0D238B]" dangerouslySetInnerHTML={{ __html: apartado.titulo }}></h2>
                                <p className="my-4" dangerouslySetInnerHTML={{ __html: apartado.descripcion }}></p>
                                
                                {/* Botones */}
                                {apartado.botones && apartado.botones.length > 0 && (
                                    <div className="flex space-x-4">
                                        {apartado.botones.map((boton, botonIndex) => (
                                            <a key={botonIndex} href={boton.link} className={`bg-black ${boton.texto === '' ? 'hidden' : ''} text-white font-medium px-4 py-2`}>
                                                {boton.texto}
                                            </a>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </section>
                    ))}
                </div>
            </div>
            {data.expertos && (
                <div className="w-full px-[30px] lg:px-[100px] mx-auto max-w-[1440px] py-10 md:py-16">
                    <h2 className="text-right text-[#0D238B] text-sm md:text-base font-semibold mb-2 wow animate__animated animate__fadeInRight" data-wow-duration="2s">
                        {data.expertos.titulo}
                    </h2>
                    <h1 className="text-right text-2xl md:text-4xl font-bold text-[#000] mb-12 wow animate__animated animate__fadeInRight" data-wow-duration="2s">
                        {data.expertos.subtitulo}
                    </h1>

                    <div className="flex flex-wrap justify-center md:justify-between items-start">
                        {imageUrls.length > 0 && (
                            <div className="w-[260px] flex flex-col justify-start text-center mb-8 lg:mb-0 group md:h-[510px]">
                                <img src={imageUrls[0]} alt="Icono Tours" className="w-full md:h-full object-contain md:mt-20 md:group-hover:mt-[0] transition-all duration-500" />
                            </div>
                        )}

                        {data.expertos.imagenes && data.expertos.imagenes.length > 1 && (
                            <div className="w-[260px] flex flex-col justify-start text-center mb-8 lg:mb-0 group md:h-[510px]">
                                <img src={imageUrls[1]} alt="Icono Visitas" className="w-full md:h-full object-contain md:mt-36 md:group-hover:mt-[70px] transition-all duration-500" />
                            </div>
                        )}

                        {data.expertos.imagenes && data.expertos.imagenes.length > 2 && (
                            <div className="w-[260px] flex flex-col justify-start text-center mb-8 lg:mb-0 group md:h-[510px]">
                                <img src={imageUrls[2]} className="w-full md:h-full object-contain md:group-hover:mt-[70px] transition-all duration-500" />
                            </div>
                        )}

                        {data.expertos.imagenes && data.expertos.imagenes.length > 3 && (
                            <div className="w-[260px] flex flex-col justify-start text-center mb-8 lg:mb-0 group md:h-[510px]">
                                <img src={imageUrls[3]} alt="Matterport Reseller" className="w-full md:h-full object-contain md:mt-10 md:group-hover:mt-[0] transition-all duration-500" />
                            </div>
                        )}
                    </div>
                </div>
            )}


                <div className="w-[1280px] 2xl:w-[1440px] max-w-full px-[30px] lg:px-[100px] mx-auto pb-10 md:pb-28">
                    <h2 className='text-[24px] font-medium text-[#0D238B] mb-[20px] wow animate__animated animate__fadeInLeft' data-wow-duration="2s">
                        GALERÍA <br /><span className='font-bold'>+ TOUR VIRTUAL</span>
                    </h2>

                    <div className='grid grid-cols-1 lg:grid-cols-3 gap-[45px]'>
                        {tours.map((tour, index) => (
                            <a href={tour.acf.boton.link} target='_blank' key={tour.id} className='relative wow animate__animated animate__fadeInDown' data-wow-duration="2s" rel="noreferrer">
                                <img 
                                    src={tour._embedded?.['wp:featuredmedia'][0]?.source_url} 
                                    alt={tour.title.rendered} 
                                    className='w-full h-auto cursor-pointer' 
                                />
                                <h3 className='text-[16px] font-medium mt-[15px]'>
                                    {tour.title.rendered}
                                </h3>
                                <button className='bg-[#0D238B] text-white py-[10px] px-[20px] mt-[10px]'>
                                    {tour.acf.boton.texto || 'Ver más'}
                                </button>
                                <p className='text-[14px] mt-[10px]'>
                                    {tour.acf.descripcion}
                                </p>
                            </a>
                        ))}
                    </div>
                </div>

                <div className={`${isOpen ? 'max-sm:h-[1170px]' : 'max-sm:h-[500px]'} w-full relative md:h-[700px] bg-white overflow-hidden mb-10 md:mb-28`}>
                    <div className="w-[1280px] 2xl:w-[1440px] max-w-full px-[30px] h-full lg:px-[100px] mx-auto flex items-center justify-center relative">
                        <h2 className="text-2xl md:text-4xl font-medium mb-4 text-[#0D238B] absolute top-[45px] left-5 md:left-[100px]">
                            NUESTRAS <br /><span className='font-bold'>CAMARAS</span>
                        </h2>
                        <motion.div
                            onClick={() => setIsOpen(!isOpen)}
                            initial={{ scale: 1 }}
                            animate={isOpen ? { scale: 50 } : { scale: [1, 1.2, 1] }}
                            transition={{
                                duration: isOpen ? 0.8 : 1, // La expansión dura 0.8s, el pulso 1s
                                ease: "easeInOut",
                                repeat: isOpen ? 0 : Infinity, // Repetir infinitamente solo en pulso, no en expansión
                                repeatType: isOpen ? "none" : "loop", // Repetir en loop solo cuando no esté expandido
                            }}
                            className="bg-[#0D238B] w-[100px] h-[100px] rounded-full cursor-pointer relative flex justify-center items-center"
                        >
                            <p className={`${!isOpen ? 'text-white' : 'text-white/0' }`}>
                                Click
                            </p>
                        </motion.div>
                    </div>

                    {isOpen && (
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ delay: 0.5, duration: 0.5 }}
                            className="absolute w-full h-full top-0 bg-[#0D238B]"
                        >
                            <div className='flex max-md:flex-col justify-center py-[45px] w-[1280px] 2xl:w-[1440px] max-w-full px-[30px] md:h-full lg:px-[100px] mx-auto'>
                                <div className='w-full md:w-1/2 text-white'>
                                    <h2 className="w-full text-2xl md:text-4xl font-medium mb-4">
                                        NUESTRAS <br /><span className='font-bold'>CAMARAS</span>
                                    </h2>
                                    <ul className='my-10 md:my-20 max-w-full w-[413px]'>
                                        {camaras.map((camara, index) => (
                                            <li key={camara.id} className={`text-xl md:text-2xl mb-6 ${activeSlide === index ? 'font-bold' : 'font-normal'}`}>
                                                {camara.title.rendered}
                                                <a href={camara.link} className="text-base pl-[20px] w-full border-b border-white py-5 gro inline-flex group">
                                                    <span className="cursor-pointer flex items-center">VER MÁS <img src='../images/icons/flecha.svg' alt='Icono Flecha' className='w-[7px] h-auto object-fill ml-[10px] group-hover:ml-[20px] transition-all duration-300' /></span>
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <div className="w-full lg:w-1/2 relative">
                                <Swiper
                                    slidesPerView={1}
                                    onSlideChange={(swiper) => setActiveSlide(swiper.activeIndex)}
                                    navigation={{
                                        nextEl: '.swiper-button-next',
                                        prevEl: '.swiper-button-prev',
                                    }}
                                    modules={[Navigation]} 
                                >
                                        {camaras.map((camara) => (
                                            <SwiperSlide key={camara.id}>
                                                <div className="p-6 bg-white rounded-lg shadow-lg flex flex-col max-w-full w-[413px] mx-auto h-[608px]">
                                                    <img 
                                                        src={camara._embedded['wp:featuredmedia'][0].source_url} 
                                                        alt={camara.title.rendered} 
                                                        className="w-full h-[179px] md:h-[279px] object-contain px-10 md:px-20 py-6 bg-[#F4F4F4] rounded-[10px] mx-auto mb-8" 
                                                    />
                                                    <h3 className="text-2xl font-bold mb-2">{camara.title.rendered}</h3>
                                                    <ul className="list-none text-left">
                                                        {camara.acf.puntos_descripcion.map((punto, idx) => (
                                                            <li key={idx} className='ml-[5px] flex gap-2'><span>●</span>{punto.descripcion}</li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                    <div className="swiper-button-prev"></div>
                                    <div className="swiper-button-next"></div>
                                </div>
                            </div>
                        </motion.div>
                    )}
                </div>

            
                {reserva && (
                    <div className='w-[1280px] 2xl:w-[1440px] max-w-full px-[30px] lg:px-[100px] mx-auto flex flex-col lg:flex-row items-center justify-between pb-20'>
                        {/* Imagen del tour */}
                        <div className='w-full lg:w-1/2 mb-[30px] lg:mb-0 wow animate__animated animate__fadeInLeft' data-wow-duration="2s">
                            <h2 className='text-[24px] font-medium text-[#0D238B] leading-[1] mb-[-20px]' dangerouslySetInnerHTML={{ __html: reserva.titulo }}>
                            </h2>
                            {reservaImageUrl && (
                                <img src={reservaImageUrl} alt='Tour Virtual' className='w-full h-auto' />
                            )}
                            <p className='text-[18px] font-light mb-[-30px]' dangerouslySetInnerHTML={{ __html: reserva.precio }}>
                            </p>
                        </div>

                        {/* Pasos de reserva */}
                        <div className='w-full lg:w-1/2 wow animate__animated animate__fadeInRight' data-wow-duration="2s">
                            <div className='space-y-[30px] py-5'>
                                {/* Punto 1 con botón */}
                                {reserva.puntos.punto_1 && (
                                    <div className='flex items-center gap-5'>
                                        <div className='bg-[#0D238B] text-white w-[50px] h-[50px] rounded-full flex items-center justify-center font-bold text-[24px] md:mr-[20px]'>
                                            1.
                                        </div>
                                        <div className='w-[calc(100%_-_70px)]'>
                                            <p className='text-[16px] font-bold'>{reserva.puntos.punto_1.texto}</p>
                                            {reserva.puntos.punto_1.boton && (
                                                <a href={reserva.puntos.punto_1.boton.link} target="_blank" className='bg-[#0D238B] inline-block text-white py-[10px] px-[20px] mt-[10px]'>
                                                    {reserva.puntos.punto_1.boton.texto}
                                                </a>
                                            )}
                                        </div>
                                    </div>
                                )}

                                {/* Punto 2 */}
                                {reserva.puntos.punto_2 && (
                                    <div className='flex items-center gap-5'>
                                        <div className='bg-[#0D238B] text-white w-[50px] h-[50px] rounded-full flex items-center justify-center font-bold text-[24px] md:mr-[20px]'>
                                            2.
                                        </div>
                                        <p className='text-[16px] w-[calc(100%_-_70px)]'>{reserva.puntos.punto_2}</p>
                                    </div>
                                )}

                                {/* Punto 3 */}
                                {reserva.puntos.punto_3 && (
                                    <div className='flex items-center gap-5'>
                                        <div className='bg-[#0D238B] text-white w-[50px] h-[50px] rounded-full flex items-center justify-center font-bold text-[24px] md:mr-[20px]'>
                                            3.
                                        </div>
                                        <p className='text-[16px] w-[calc(100%_-_70px)]'>{reserva.puntos.punto_3}</p>
                                    </div>
                                )}

                                {/* Punto 4 */}
                                {reserva.puntos.punto_4 && (
                                    <div className='flex items-center gap-5'>
                                        <div className='bg-[#0D238B] text-white w-[50px] h-[50px] rounded-full flex items-center justify-center font-bold text-[24px] md:mr-[20px]'>
                                            4.
                                        </div>
                                        <p className='text-[16px] w-[calc(100%_-_70px)]'>{reserva.puntos.punto_4}</p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}

        </div>
    );
};

export default Tour;