import React, { useEffect, useState } from 'react';
import WOW from 'wowjs';
import 'animate.css';

const Contacto = () => {
    const [acfData, setAcfData] = useState(null); // Estado para almacenar los datos del ACF
    const [fondoUrl, setFondoUrl] = useState(''); // Estado para almacenar la URL del fondo
    const [loading, setLoading] = useState(true);

    const [formData, setFormData] = useState({
        nombre: '',
        apellido: '',
        email: '',
        empresa: '',
        telefono: '',
        industria: ''
    });

    const [status, setStatus] = useState('');

    // Manejar cambios en el formulario
    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    // Manejar el envío del formulario
    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch('https://interprika.com/cms/wp-json/custom/v1/send-email', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            if (response.ok) {
                setStatus('Correo enviado con éxito!');
                setFormData({ nombre: '', apellido: '', email: '', empresa: '', telefono: '', industria: '' });
            } else {
                setStatus('Error al enviar el correo.');
            }
        } catch (error) {
            console.error('Error:', error);
            setStatus('Error al conectar con el servidor.');
        }
    };

    useEffect(() => {

        // Fetch data from the API
        const fetchData = async () => {
            try {
                const response = await fetch('https://interprika.com/cms/wp-json/wp/v2/pages/185');
                const data = await response.json();

                // Guarda los datos del ACF en el estado
                setAcfData(data.acf);

                // Si existe el ID del fondo, hacemos un fetch para obtener la URL de la imagen
                if (data.acf?.fondo) {
                    const imageResponse = await fetch(`https://interprika.com/cms/wp-json/wp/v2/media/${data.acf.fondo}`);
                    const imageData = await imageResponse.json();
                    setFondoUrl(imageData.source_url); // Guarda la URL del fondo en el estado
                }

                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    if (loading) {
        return (
            <div className='w-full h-full bg-black fixed top-0 z-[999999999] flex flex-col justify-center items-center'>
                <img className='w-full h-[44px] object-contain' src='../images/icons/logo.svg' alt="" />
            </div>
        );
    }

    return (
        <div>
            {/* Header Section */}
            <div className='bg-[#000] overflow-hidden relative'>
                {/* Imagen de fondo del header */}
                {fondoUrl && (
                    <img
                        className='w-full max-md:h-[300px] object-contain'
                        src={fondoUrl}
                        alt="Header Background"
                    />
                )}
                
                {/* Contenido del header */}
                <div className='flex flex-col justify-center items-center w-full h-full top-[-50px] absolute max-md:pt-12'>
                    <div className='w-[800px] mx-auto wow animate__animated animate__fadeInLeft' data-wow-duration="2s">
                        <h2 className='max-md:pl-5 text-white text-[16px] md:text-[48px] leading-[22px] md:leading-[48px] pb-2 uppercase' dangerouslySetInnerHTML={{ __html: acfData?.titulo }}>
                        </h2>
                    </div>
                </div>

                <div className='mt-[-100px]'>
                    <div className='clip-diagonal-orange'></div>
                    <div className='clip-diagonal-white'></div>
                </div>
            </div>

            {/* Form Section */}
            <div className="pt-10 pb-16 overflow-hidden">
                <div className="w-[1280px] 2xl:w-[1440px] max-w-full px-[30px] lg:px-[100px] mx-auto">
                    <div className="flex flex-col lg:flex-row justify-between items-center pt-10">
                        <div className="w-full lg:w-1/2 lg:pr-[30px]">
                            <h3 className="text-2xl font-medium mb-4 uppercase wow animate__animated animate__fadeInDown" data-wow-duration="2s">
                                conoce nuestras<br />
                                <span className="text-[#F89D38] font-bold">oficinas virtuales</span>
                            </h3>
                            <form onSubmit={handleSubmit} className="space-y-4 wow animate__animated animate__fadeInLeft" data-wow-duration="2s">
                                <input 
                                    type="text" 
                                    name="nombre" 
                                    placeholder="Nombre*" 
                                    className="w-full px-4 py-2 border bg-[#F2EEEE]" 
                                    value={formData.nombre} 
                                    onChange={handleChange} 
                                    required 
                                />
                                <input 
                                    type="text" 
                                    name="apellido" 
                                    placeholder="Apellido*" 
                                    className="w-full px-4 py-2 border bg-[#F2EEEE]" 
                                    value={formData.apellido} 
                                    onChange={handleChange} 
                                    required 
                                />
                                <input 
                                    type="text" 
                                    name="empresa" 
                                    placeholder="Empresa*" 
                                    className="w-full px-4 py-2 border bg-[#F2EEEE]" 
                                    value={formData.empresa} 
                                    onChange={handleChange} 
                                />
                                <input 
                                    type="email" 
                                    name="email" 
                                    placeholder="Email*" 
                                    className="w-full px-4 py-2 border bg-[#F2EEEE]" 
                                    value={formData.email} 
                                    onChange={handleChange} 
                                    required 
                                />
                                <input 
                                    type="tel" 
                                    name="telefono" 
                                    placeholder="Teléfono móvil*" 
                                    className="w-full px-4 py-2 border bg-[#F2EEEE]" 
                                    value={formData.telefono} 
                                    onChange={handleChange} 
                                />
                                <input 
                                    type="text" 
                                    name="industria" 
                                    placeholder="Industria*" 
                                    className="w-full px-4 py-2 border bg-[#F2EEEE]" 
                                    value={formData.industria} 
                                    onChange={handleChange} 
                                />
                                <div className="flex justify-between">
                                    <button type="submit" className="w-[48%] bg-[#000] text-white font-medium px-4 py-2">Enviar</button>
                                </div>
                            </form>
                            {status && <p>{status}</p>}
                        </div>
                        <img src="../images/contacto-form.png" alt="Tecnología 3D" className="w-full lg:w-1/2 h-auto wow animate__animated animate__fadeInRight" data-wow-duration="2s" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contacto;