import React, { Suspense, useRef } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { OrbitControls, useGLTF } from '@react-three/drei';

const Logo3D = React.forwardRef((props, ref) => {
  const { scene } = useGLTF('/images/3d/interprika_logo_white.glb');
  return <primitive ref={ref} object={scene} scale={0.4} />;
});

const Scene = () => {
  const modelRef = useRef();

  // Controlar la rotación del modelo con el movimiento del ratón dentro de Canvas
  useFrame(({ mouse }) => {
    if (modelRef.current) {
      modelRef.current.rotation.y = mouse.x * Math.PI * 0.02;
      modelRef.current.rotation.x = mouse.y * Math.PI * 0.02;
    }
  });

  return (
    <>
      <ambientLight intensity={1} />
      <directionalLight intensity={1} position={[-1, 1, 1]} />
      <Suspense fallback={null}>
        <Logo3D ref={modelRef} />
      </Suspense>
      <OrbitControls
        enablePan={false}
        enableZoom={false}
        minPolarAngle={Math.PI / 2.2}
        maxPolarAngle={Math.PI / 2}
        minAzimuthAngle={-Math.PI / 30}
        maxAzimuthAngle={Math.PI / 30}
      />
    </>
  );
};

const Logo3DCanvas = () => {
  return (
    <Canvas className="cursor-move" style={{ width: '100%', height: '100%' }}>
      <Scene />
    </Canvas>
  );
};

export default Logo3DCanvas;