import React, { useState } from 'react';

const Header = () => {

    const [openMenu, setOpenMenu ] = useState(false);
    const [submenuServicios, setSubmenuServicios] = useState(false)

    return (
        <header className='py-[30px] lg:py-[35px] bg-black'>
            <div className='bg-black fixed w-full z-[9999999] top-0'>
                <div className="relative p-4 flex justify-between items-center w-[1280px] 2xl:w-[1440px] max-w-full px-[30px] lg:px-[100px] mx-auto">
                    <a href={`${process.env.PUBLIC_URL}/`}>
                        <img src={`${process.env.PUBLIC_URL}/images/icons/logo.svg`} className='max-lg:w-[150px]' alt="" />
                    </a>
                    <nav className={`${openMenu? 'right-0' : 'right-[-500px]'} max-lg:absolute max-lg:w-[500px] max-lg:max-w-full transition-all max-lg:top-0 max-lg:h-[100vh] max-lg:bg-black max-lg:justify-center max-lg:items-center max-lg:flex max-lg:flex-col`}>
                        <div className={`${openMenu? 'right-[10px]' : 'hidden'} lg:hidden fixed z-[90999] mb-10 top-[35px] w-[40px] h-[40px] text-white flex-col gap-0 cursor-pointer justify-center`} onClick={()=>(setOpenMenu(!openMenu))}>
                            <div className='w-6 h-[3px] rotate-45 mb-[-3px] bg-white'></div>
                            <div className='w-6 h-[3px] -rotate-45 bg-white'></div>
                        </div>
                        <ul className='flex max-lg:w-[300px] max-lg:flex-col justify-center items-center gap-3 lg:gap-6'>
                            <li className='text-white uppercase tracking-[3px] text-[12px]'>
                                <a href={`${process.env.PUBLIC_URL}/`}>
                                    Inicio
                                </a>
                            </li>
                            <li className='text-white uppercase tracking-[3px] text-[12px] relative cursor-pointer max-lg:text-center' 
                                onClick={()=>(setSubmenuServicios(!submenuServicios))}
                            >
                                Servicios
                                <ul className={`${submenuServicios? '' : 'lg:hidden' } max-lg:text-center flex flex-col bg-black px-4 max-lg:pb-0 absolute max-lg:relative w-[210px]`}>
                                    <li className='text-white uppercase tracking-[3px] text-[12px]'>
                                        <a className='pt-[12px] pb-[6px] inline-flex' href={`${process.env.PUBLIC_URL}/servicios/tour-virtual`}>
                                            tour virtual
                                        </a>
                                    </li>
                                    <li className='text-white uppercase tracking-[3px] text-[12px]'>
                                        <a className='py-[6px] inline-flex' href={`${process.env.PUBLIC_URL}/servicios/marketing-digital`}>
                                            MARKETING DIGITAL
                                        </a>
                                    </li>
                                    <li className='text-white uppercase tracking-[3px] text-[12px]'>
                                        <a className='py-[6px] inline-flex' href={`${process.env.PUBLIC_URL}/servicios/tecnologia-3d`}>
                                            Tecnología 3d
                                        </a>
                                    </li>
                                    <li className='text-white uppercase tracking-[3px] text-[12px]'>
                                        <a className='pt-[6px] pb-[12px] inline-flex' href={`${process.env.PUBLIC_URL}/servicios/matterport`}>
                                            MATTERPORT
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            {/*<li className='text-white uppercase tracking-[3px] text-[12px]'>
                                <a href={`${process.env.PUBLIC_URL}/proyectos`}>
                                    Proyectos
                                </a>
                            </li>*/}
                            <li className='text-white uppercase tracking-[3px] text-[12px]'>
                                <a href={`${process.env.PUBLIC_URL}/blog`}>
                                    Blog
                                </a>
                            </li>
                            <li className='text-white uppercase tracking-[3px] text-[12px]'>
                                <a href={`${process.env.PUBLIC_URL}/contacto`}>
                                    Contacto
                                </a>
                            </li>
                        </ul>
                    </nav>
                    <div className='lg:hidden flex flex-col gap-1 cursor-pointer h-[30px] justify-center' onClick={()=>(setOpenMenu(!openMenu))}>
                        <div className='w-5 h-[2px] bg-white'></div>
                        <div className='w-5 h-[2px] bg-white'></div>
                        <div className='w-5 h-[2px] bg-white'></div>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header;