import React from 'react';
import { createRoot } from 'react-dom/client'; // Importa createRoot desde react-dom
import { BrowserRouter } from 'react-router-dom';
import './tailwind.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const rootElement = document.getElementById('root'); // Obtén el elemento raíz del DOM
const root = createRoot(rootElement); // Crea la raíz usando createRoot

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();